<template>
	<main>
		<div class="table-box">
			<div class="tit">事件列表</div>
			<div class="list-wrap" v-if="list.length > 0">
				<div class="list">
					<a-link :to="'/sentry/project/detail?error_id='+item.error_id+'&type='+query.type+'&project_id='+query.project_id" class="item" v-for="(item,index) in list" :key="index">
						<div class="title-wrap">
							<div class="title">
								<el-tag class="level" :type="query.type === 'error' ? 'danger' : query.type === 'http' ? 'warning' : query.type === 'resource' ? 'info' : query.type === 'log' ? 'success' : ''">{{item.type}}</el-tag>
								<div class="text">{{item.custom || item.name}}</div>
							</div>
							<div class="stat">
								<div class="li">上报次数：<i>{{ item.number }}</i> 次</div>
							</div>
						</div>
						<div class="attr-wrap">
							<div class="message">{{item.message}}</div>
							<div class="time">最后上报时间：{{timeToString(item.create_time)}}</div>
						</div>
					</a-link>
				</div>
				<div class="page">
					<el-pagination hide-on-single-page layout="prev,pager,next,total" :page="page" :total="total" @current-change="choosePage"/>
				</div>
			</div>
			<div class="empty-wrap" v-else>
				<a-empty :status="3"/>
			</div>
		</div>
	</main>
</template>
<script>
import {event} from "@/api/sentry";
import {sentry} from "@/config";
import {timeToString} from "@/service/admin/common";
export default {
	data(){
		return {
			query:{},
			type_list:sentry.type,
			list:[],
			total:0,
			page:1,
			limit:10,
		}
	},
	created() {
		this.query = this.$route.query;
	},
	mounted() {
		this.getEventList();
	},
	methods:{
		timeToString,
		choosePage(page){
			console.log(page);
			this.page = page;
			this.getEventList();
		},
		getEventList(){
			let type = "";
			switch (this.query.type){
				case 'error':
					type = "JAVASCRIPT,VUE,REACT,PROMISE,ROUTE";
					break;
				case 'http':
					type = "HTTP";
					break;
				case 'resource':
					type = "RESOURCE";
					break;
				case 'log':
					type = "LOG";
					break;
				case 'unknown':
					type = "UNKNOWN,UNKNOWN_FUNCTION";
					break;
			}
			event.group({
				type:type,
				id:this.query.project_id,
				page:this.page,
				limit:this.limit,
			}).then((res)=>{
				this.list = res.list;
				this.total = res.count;
			})
		}
	}
}
</script>
<style lang="scss" scoped>
.table-box{margin-top: 15px;padding: 15px;background: #fff;box-shadow: 0 0 20px 5px rgba(0,0,0,.1);
	.tit{font-weight: bold;}
	.list-wrap{margin-top: 15px;
		.list{
			.item{padding: 15px 0;border-bottom: 1px solid $border;cursor: pointer;
				.title-wrap{display: flex;justify-content: space-between;
					.title{display: flex;flex: 1;margin-right: 15px;
						.level{margin-right: 10px;}
						.text{font-weight: bold;}
					}
					.stat{font-size: 14px;
						i{font-weight: bold;color:$admin_main;}
					}
				}
				.attr-wrap{display: flex;justify-content: space-between;color:#666;margin-top: 5px;font-size: 14px;
					.message{flex: 1;margin-right: 15px;}
				}
			}
		}
		.page{margin-top: 15px;}
	}
	.empty-wrap{margin-top: 15px;}
}
</style>
